.App {
  border: 1px solid black;
}

body {
  font-family: 'Roboto', sans-serif;
  overscroll-behavior: none;
}

.logo {
  margin: 100px auto;
  width: 300px;
}

.logo-icon {
  padding-right: 10px;
}

.logo-icon, .logo-text {
  height: 35px;
}

.header {
  background-color: #222;
  height: 250px;
  padding: 20px;
  margin-bottom: 50px;
  color: white;
}

.footer {
  background-color: #222;
  height: 20px;
  padding: 10px 10px;
  color: white;
  display: flex;
  margin-top: auto;
  justify-content: space-between;
  font-family: 'Lato';
  font-size: 14px;
}

span.powered-by-container {
  display: flex;
}

span.powered-by {
  display: flex;
}

.exchange-description {
  text-align: justify;
}

a.powered-by {
  display: flex;
}

.content {
  width: 550px;
  margin: 0px auto;
  padding: 0px 16px;
  min-height: calc(100vh - 414px + 32px);
  overflow: auto;
}

.modal {
  background-color: #fbfbfb;
  font-size: 14px;
}

@media (max-width: 550px) {
  .content {
    width: 75%;
  }
  .job-location {
    display: none !important;
  }
  .post-date {
    display: none !important;
  }
}

.job-app-grouped-input {
  display: flex;
  justify-content: space-between;
}

.upload-status {
  color: darkgreen;
  margin-left: 15px;
  font-size: 14px;
}

.job-app-submit {
}

.section {
  width: 100%;
  padding-bottom: 40px;
}

.section-title {
  padding-bottom: 5px;
  border-bottom: 2px solid black;
}

.section-content-item {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding-bottom: 5px;
}

.flex-display {
  display: flex;
}

.none-display {
  display: none;
}

.job-location {
  display: flex;
}

.job-description {
  padding-top: 5px;
  padding-left: 15px;
  padding-bottom: 25px;
  flex-direction: column;
  font-family: 'Roboto Condensed', sans-serif;
}

.last-job-description {
  padding-bottom: 0px;
}

.job-description p {
  margin: 0;
  padding-bottom: 10px;
  text-align: justify;
}

.job-description p:last-of-type {
  padding-bottom: 0px;
}

.job-link {
  display: flex;
}

.section-title {
  font-family: 'Roboto';
}

.section-content {
  padding-top: 16px;
}

.post-title {
  width: 75%;
  display: block;
}

.censored {
  color: transparent;
  text-shadow: 0 0 5px rgba(0,0,0,0.5);
  background-color: black;
  display: inline;
  margin-left: 5px;
}

ul {
    padding-top: 0px;
    margin-top: 0px;
}

li {
    padding-bottom: 5px;
}

a {
    color: black;
}

button {
    background-color: white;
    margin-top: 15px;
    padding: 5px;
    border: 1px solid #8f8f8f;
    border-radius: 2px;
    cursor: pointer;
}

button:hover {
    background-color: #f7f7f7;
}

textarea.job-app-input {
    width: 580px;
    height: 70px;
    padding: 10px;
    border: 1px solid #8f8f8f;
    border-radius: 2px;
}

input.job-app-input {
    width: 100%;
    margin-top: 8px;
    margin-left: 10px;
    padding: 5px;
    height: 15px;
}

.job-app-email {
    width: 250px;
}

.articles {
    /*display: none; */
}

.plus {
  font-family: Arial;
  font-weight: 600;
  transition: transform 1.0s ease;
  margin-right: 5px;
  color: #4a4a4a;
}

.rotate {
  transform: rotate(-45deg);
}

.job-title {
  display: flex;
  padding-bottom: 3px;
  font-weight: 500;
  /* grey */
  color: #4a4a4a;
}

.section-title, .job-title, .job-location, .post-title, .post-date {
  user-select: none;
}

.job-title:hover, .post-title:hover {
  color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a.powered-by {
  color: white;
  padding-left: 8px;
  text-decoration: none;
}

a.mailto {
  color: white;
  text-decoration: none;
}
